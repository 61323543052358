import { toast } from "react-toastify";
import "./toasts.scss";

const autoCloseTime = 2000;

enum notifyStatuses {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
}

export function notify(
  message: string,
  autoClose: boolean,
  type?: keyof typeof notifyStatuses,
  timeout?: number,
  closeButton: boolean = false
) {
  switch (type) {
    case "success":
      toast(message, {
        className: "success-toast toast-text",
        position: "bottom-right",
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
      });
      break;
    case "error":
      console.log("test " + timeout + " " + autoCloseTime);
      toast(message, {
        className: "error-toast toast-text",
        position: "bottom-right",
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
      });
      break;
    case "warning":
      toast(message, {
        className: "warning-toast toast-text",
        position: "bottom-right",
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
      });
      break;
    case "info":
      toast(message, {
        className: "regular-toast toast-text",
        position: "bottom-right",
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
      });
      break;
    default:
      toast(message, {
        className: "regular-toast toast-text",
        position: "bottom-right",
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
      });
      break;
  }
}
